<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="End Date"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-btn color="primary" @click="search" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="success" @click="excel">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" dense fixed-header>
        <template v-slot:item.no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.unit_price="{ item }">
          {{ formatPrice(item.unit_price) }}
        </template>
        <template v-slot:item.total_price="{ item }">
          {{ formatPrice(item.total_price) }}
        </template>
        <template v-slot:item.rate="{ item }">
          {{ formatPrice(item.rate) }}
        </template>
        <template v-slot:item.unitPriceIdr="{ item }">
          {{ formatPrice(item.unitPriceIdr) }}
        </template>
        <template v-slot:item.totalPriceIdr="{ item }">
          {{ formatPrice(item.totalPriceIdr) }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";

export default {
  name: "report-transaction-rr",
  data() {
    return {
      params: {
        startDate: moment()
          .startOf("month")
          .format("yyyy-MM-DD"),
        endDate: moment()
          .endOf("month")
          .format("yyyy-MM-DD"),
      },
      menuStartDate: false,
      menuEndDate: false,
      items: [],
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "DO#",
          sortable: false,
          value: "do_num",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "DO DATE",
          sortable: false,
          value: "do_date",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "PO#",
          sortable: false,
          value: "po_num",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Vendor Name",
          sortable: false,
          value: "supp",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "RR#",
          sortable: false,
          value: "rr_num",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "RR DATE",
          sortable: false,
          value: "rr_date",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "MFR",
          sortable: false,
          value: "mfr",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part Number",
          sortable: false,
          value: "part_num",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part Name",
          sortable: false,
          value: "part_name",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part Desc",
          sortable: false,
          value: "part_desc",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "PO QTY",
          sortable: false,
          value: "po_qty",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "DO QTY",
          sortable: false,
          value: "do_qty",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "UM",
          sortable: false,
          value: "um",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "CURR",
          sortable: false,
          value: "curr",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Unit Price",
          align: "center",
          sortable: false,
          value: "unit_price",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Total Price",
          align: "center",
          sortable: false,
          value: "total_price",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Rate",
          align: "center",
          sortable: false,
          value: "rate",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Unit Price IDR",
          align: "center",
          sortable: false,
          value: "unitPriceIdr",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Total Price IDR",
          align: "center",
          sortable: false,
          value: "totalPriceIdr",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/transactionRRExcel", this.params)
        .then(response => {
          fileDownload(response.data, `transaction-rr.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/transactionRR", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
